
import SubmitForm from './SubmitForm';


const BundleForm = () => {
    return (
        <>
            <SubmitForm />
        </>
    )
}

export default BundleForm