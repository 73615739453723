
import photo1 from '../assets/photos/proj-photos/Craft_Lindsey-01.jpg'
import photo2 from '../assets/photos/proj-photos/Craft_Lindsey-02.jpg'
import photo3 from '../assets/photos/proj-photos/Craft_Lindsey-03.jpg'
import photo4 from '../assets/photos/proj-photos/Craft_Lindsey-04.jpg'
import photo5 from '../assets/photos/proj-photos/Craft_Lindsey-05.jpg'
import photo6 from '../assets/photos/proj-photos/Craft_Lindsey-06.jpg'
import photo7 from '../assets/photos/proj-photos/Craft_Lindsey-07.jpg'
import photo8 from '../assets/photos/proj-photos/Craft_Lindsey-08.jpg'
import photo9 from '../assets/photos/proj-photos/Craft_Lindsey-09.jpg'
import photo10 from '../assets/photos/proj-photos/Craft_Lindsey-10.jpg'
import photo11 from '../assets/photos/proj-photos/Craft_Lindsey-11.jpg'
import photo12 from '../assets/photos/proj-photos/Craft_Lindsey-12.jpg'
import photo13 from '../assets/photos/proj-photos/Craft_Lindsey-13.jpg'
import photo14 from '../assets/photos/proj-photos/Craft_Lindsey-14.jpg'
import photo15 from '../assets/photos/proj-photos/Craft_Lindsey-15.jpg'
import photo16 from '../assets/photos/proj-photos/Craft_Lindsey-16.jpg'
import photo17 from '../assets/photos/proj-photos/Craft_Lindsey-17.jpg'
import photo18 from '../assets/photos/proj-photos/Craft_Lindsey-18.jpg'
import photo19 from '../assets/photos/proj-photos/Craft_Lindsey-19.jpg'
import photo20 from '../assets/photos/proj-photos/Craft_Lindsey-20.jpg'
import photo21 from '../assets/photos/proj-photos/Craft_Lindsey-21.jpg'
import photo22 from '../assets/photos/proj-photos/Craft_Lindsey-22.jpg'
import photo23 from '../assets/photos/proj-photos/Craft_Lindsey-23.jpg'
import photo24 from '../assets/photos/proj-photos/Craft_Lindsey-24.jpg'
import photo25 from '../assets/photos/proj-photos/Craft_Lindsey-25.jpg'
import photo26 from '../assets/photos/proj-photos/Craft_Lindsey-26.jpg'
import photo27 from '../assets/photos/proj-photos/Craft_Lindsey-27.jpg'
import photo28 from '../assets/photos/proj-photos/Craft_Lindsey-28.jpg'
import photo29 from '../assets/photos/proj-photos/Craft_Lindsey-29.jpg'
import photo30 from '../assets/photos/proj-photos/Craft_Lindsey-30.jpg'
import photo31 from '../assets/photos/proj-photos/Craft_Lindsey-31.jpg'
import photo32 from '../assets/photos/proj-photos/Craft_Lindsey-32.jpg'
import photo33 from '../assets/photos/proj-photos/Craft_Lindsey-33.jpg'
import photo34 from '../assets/photos/proj-photos/Craft_Lindsey-34.jpg'
import photo35 from '../assets/photos/proj-photos/Craft_Lindsey-35.jpg'
import photo36 from '../assets/photos/proj-photos/Craft_Lindsey-36.jpg'
import photo37 from '../assets/photos/proj-photos/Craft_Lindsey-37.jpg'
import photo38 from '../assets/photos/proj-photos/Craft_Lindsey-38.jpg'
import photo39 from '../assets/photos/proj-photos/Craft_Lindsey-39.jpg'
import photo40 from '../assets/photos/proj-photos/Craft_Lindsey-40.jpg'
import photo41 from '../assets/photos/proj-photos/Craft_Lindsey-41.jpg'
import photo42 from '../assets/photos/proj-photos/Craft_Lindsey-42.jpg'
import photo43 from '../assets/photos/proj-photos/Craft_Lindsey-43.jpg'
import photo44 from '../assets/photos/proj-photos/Craft_Lindsey-44.jpg'
import photo45 from '../assets/photos/proj-photos/Craft_Lindsey-45.jpg'
import photo46 from '../assets/photos/proj-photos/Craft_Lindsey-46.jpg'
import photo47 from '../assets/photos/proj-photos/Craft_Lindsey-47.jpg'
import photo48 from '../assets/photos/proj-photos/Craft_Lindsey-48.jpg'
import photo49 from '../assets/photos/proj-photos/Craft_Lindsey-49.jpg'
import photo50 from '../assets/photos/proj-photos/Craft_Lindsey-50.jpg'
import photo51 from '../assets/photos/proj-photos/Craft_Lindsey-51.jpg'
import photo52 from '../assets/photos/proj-photos/Craft_Lindsey-52.jpg'
import photo53 from '../assets/photos/proj-photos/Craft_Lindsey-53.jpg'
import photo54 from '../assets/photos/proj-photos/Craft_Lindsey-54.jpg'
import photo55 from '../assets/photos/proj-photos/Craft_Lindsey-55.jpg'
import photo56 from '../assets/photos/proj-photos/Craft_Lindsey-56.jpg'
import photo57 from '../assets/photos/proj-photos/Craft_Lindsey-57.jpg'
import photo58 from '../assets/photos/proj-photos/Craft_Lindsey-58.jpg'
import photo59 from '../assets/photos/proj-photos/Craft_Lindsey-59.jpg'
import photo60 from '../assets/photos/proj-photos/Craft_Lindsey-60.jpg'
import photo61 from '../assets/photos/proj-photos/Craft_Lindsey-61.jpg'
import photo62 from '../assets/photos/proj-photos/Craft_Lindsey-62.jpg'
import photo63 from '../assets/photos/proj-photos/Craft_Lindsey-63.jpg'
import photo64 from '../assets/photos/proj-photos/Craft_Lindsey-64.jpg'
// new photos below as of 5/21/2024
import photo65 from '../assets/photos/proj-photos/DSC_0786.jpg'
import photo66 from '../assets/photos/proj-photos/DSC_0787.jpg'
import photo67 from '../assets/photos/proj-photos/DSC_0788.jpg'
import photo68 from '../assets/photos/proj-photos/DSC_0789.jpg'
import photo69 from '../assets/photos/proj-photos/DSC_0790.jpg'
import photo70 from '../assets/photos/proj-photos/DSC_0791.jpg'
import photo71 from '../assets/photos/proj-photos/DSC_0792.jpg'
import photo72 from '../assets/photos/proj-photos/DSC_0793.jpg'
import photo73 from '../assets/photos/proj-photos/DSC_0794.jpg'
import photo74 from '../assets/photos/proj-photos/DSC_0795.jpg'
import photo75 from '../assets/photos/proj-photos/DSC_0796.jpg'
import photo76 from '../assets/photos/proj-photos/DSC_0797.jpg'
import photo77 from '../assets/photos/proj-photos/DSC_0798.jpg'
import photo78 from '../assets/photos/proj-photos/DSC_0799.jpg'
import photo79 from '../assets/photos/proj-photos/DSC_0800.jpg'
import photo80 from '../assets/photos/proj-photos/DSC_0801.jpg'
import photo81 from '../assets/photos/proj-photos/DSC_0802.jpg'
import photo82 from '../assets/photos/proj-photos/DSC_0803.jpg'
import photo83 from '../assets/photos/proj-photos/DSC_0804.jpg'
import photo84 from '../assets/photos/proj-photos/DSC_0805.jpg'
import photo85 from '../assets/photos/proj-photos/DSC_0806.jpg'
import photo86 from '../assets/photos/proj-photos/DSC_0807.jpg'
import photo87 from '../assets/photos/proj-photos/DSC_0808.jpg'
import photo88 from '../assets/photos/proj-photos/DSC_0809.jpg'
import photo89 from '../assets/photos/proj-photos/DSC_0810.jpg'
import photo90 from '../assets/photos/proj-photos/DSC_0811.jpg'
import photo91 from '../assets/photos/proj-photos/DSC_0812.jpg'
import photo92 from '../assets/photos/proj-photos/DSC_0813.jpg'
import photo93 from '../assets/photos/proj-photos/DSC_0814.jpg'
import photo94 from '../assets/photos/proj-photos/DSC_0815.jpg'
import photo95 from '../assets/photos/proj-photos/DSC_0816.jpg'
import photo96 from '../assets/photos/proj-photos/DSC_0817.jpg'
import photo97 from '../assets/photos/proj-photos/DSC_0818.jpg'
import photo98 from '../assets/photos/proj-photos/DSC_0819.jpg'
import photo99 from '../assets/photos/proj-photos/DSC_0820.jpg'
import photo100 from '../assets/photos/proj-photos/DSC_0821.jpg'
import photo101 from '../assets/photos/proj-photos/DSC_0821.jpg'
import photo102 from '../assets/photos/proj-photos/DSC_0822.jpg'
import photo103 from '../assets/photos/proj-photos/DSC_0823.jpg'
import photo104 from '../assets/photos/proj-photos/DSC_0824.jpg'
import photo105 from '../assets/photos/proj-photos/DSC_0825.jpg'
import photo106 from '../assets/photos/proj-photos/DSC_0826.jpg'
import photo107 from '../assets/photos/proj-photos/DSC_0827.jpg'
import photo108 from '../assets/photos/proj-photos/DSC_0828.jpg'


const popularArray = [
  { id: 5, title: 'Coaster holder - with flowers and gold foil', src: photo5, width: 1920, height: 1080 },
  { id: 7, title: 'Jewelry Medium Tray - with flowers and silver foil', src: photo7, width: 1920, height: 1080 },
  { id: 14, title: 'Round Coaster Set - with flowers and gold foil', src: photo14, width: 1920, height: 1080 },
  { id: 19, title: 'Round Coaster Set - with flowers and gold foil', src: photo19, width: 1920, height: 1080 },
  { id: 24, title: 'Round Coaster - with flowers and gold foil', src: photo24, width: 1920, height: 1080 },
  { id: 28, title: 'XL Circle Tray - with flowers and gold foil with gold hardware', src: photo28, width: 1920, height: 1080 },
  { id: 30, title: 'Large Rectangle Tray - with flowers and gold foil with gold hardware', src: photo30, width: 1920, height: 1080 },
  { id: 31, title: 'Large Rectangle Tray - with flowers and gold foil with gold hardware', src: photo31, width: 1920, height: 1080 },
  { id: 32, title: 'Large Rectangle Tray - with flowers and gold foil with gold hardware', src: photo32, width: 1920, height: 1080 },
  { id: 34, title: 'Custom Wedding Bundle - with Silver Clear Tray', src: photo34, width: 1920, height: 1080 },
  { id: 35, title: 'Custom Wedding Bundle - with Silver Clear Tray', src: photo35, width: 1920, height: 1080 },
  { id: 36, title: 'Three Tier Tray - with flowers and small gold foil', src: photo36, width: 1920, height: 1080 },
  { id: 38, title: 'Three Tier Tray - with flowers and small gold foil', src: photo38, width: 1920, height: 1080 },
  { id: 39, title: 'Custom Wedding Bundle - with Wood Tray', src: photo39, width: 1920, height: 1080 }
]

const trayArray = [

  { id: 7, title: 'Jewelry Medium Tray - with flowers and silver foil', src: photo7, width: 1920, height: 1080 },
  { id: 8, title: 'Jewelry Medium Tray - with flowers and silver foil', src: photo8, width: 1920, height: 1080 },
  { id: 9, title: 'Jewelry Medium Tray - with flowers and silver foil', src: photo9, width: 1920, height: 1080 },
  { id: 25, title: 'Jewelry Medium Tray - with flowers and gold foil', src: photo25, width: 1920, height: 1080 },
  { id: 26, title: 'Jewelry Medium Tray - with flowers and gold foil', src: photo26, width: 1920, height: 1080 },
  { id: 27, title: 'Jewelry Medium Tray - with flowers and gold foil', src: photo27, width: 1920, height: 1080 },
  { id: 28, title: 'XL Circle Tray - with flowers and gold foil with gold hardware', src: photo28, width: 1920, height: 1080 },
  { id: 29, title: 'XL Circle Tray - with flowers and gold foil with gold hardware', src: photo29, width: 1920, height: 1080 },
  { id: 30, title: 'Large Rectangle Tray - with flowers and gold foil with gold hardware', src: photo30, width: 1920, height: 1080 },
  { id: 31, title: 'Large Rectangle Tray - with flowers and gold foil with gold hardware', src: photo31, width: 1920, height: 1080 },
  { id: 32, title: 'Large Rectangle Tray - with flowers and gold foil with gold hardware', src: photo32, width: 1920, height: 1080 },
  { id: 33, title: 'Custom Wedding Bundle - with Silver Clear Tray', src: photo33, width: 1920, height: 1080 },
  { id: 34, title: 'Custom Wedding Bundle - with Silver Clear Tray', src: photo34, width: 1920, height: 1080 },
  { id: 35, title: 'Custom Wedding Bundle - with Silver Clear Tray', src: photo35, width: 1920, height: 1080 },
  { id: 36, title: 'Three Tier Tray - with flowers and small gold foil', src: photo36, width: 1920, height: 1080 },
  { id: 37, title: 'Three Tier Tray - with flowers and small gold foil', src: photo37, width: 1920, height: 1080 },
  { id: 38, title: 'Three Tier Tray - with flowers and small gold foil', src: photo38, width: 1920, height: 1080 },
  { id: 39, title: 'Custom Wedding Bundle - with Wood Tray', src: photo39, width: 1920, height: 1080 },
  { id: 40, title: 'Large Rectangle Tray - rose petals and white', src: photo40, width: 1920, height: 1080 },
  { id: 42, title: 'Large Rectangle Tray - blue, white, and gold foil', src: photo42, width: 1920, height: 1080 },
  { id: 43, title: 'XL Circle Tray - rose petals and gold foil with white underneath', src: photo43, width: 1920, height: 1080 },
  { id: 45, title: 'Large Rectangle Tray - white, gold foil, and greenery with gold hardware', src: photo45, width: 1920, height: 1080 },

]

const coasterArray = [

  { id: 5, title: 'Coaster holder - with flowers and gold foil', src: photo5, width: 1920, height: 1080 },
  { id: 6, title: 'Coaster holder - with flowers and gold foil', src: photo6, width: 1920, height: 1080 },
  { id: 10, title: 'Coaster holder - with flowers and gold foil', src: photo10, width: 1920, height: 1080 },
  { id: 11, title: 'Coaster holder - with flowers and gold foil', src: photo11, width: 1920, height: 1080 },
  { id: 12, title: 'Coaster holder - with flowers and gold foil', src: photo12, width: 1920, height: 1080 },
  { id: 13, title: 'Coaster holder - with flowers and gold foil', src: photo13, width: 1920, height: 1080 },
  { id: 14, title: 'Round Coaster Set - with flowers and gold foil', src: photo14, width: 1920, height: 1080 },
  { id: 15, title: 'Round Coaster - with flowers and gold foil', src: photo15, width: 1920, height: 1080 },
  { id: 16, title: 'Round Coaster - with flowers and gold foil', src: photo16, width: 1920, height: 1080 },
  { id: 17, title: 'Round Coaster - with flowers and gold foil', src: photo17, width: 1920, height: 1080 },
  { id: 18, title: 'Round Coaster - with flowers and gold foil', src: photo18, width: 1920, height: 1080 },
  { id: 19, title: 'Round Coaster Set - with flowers and gold foil', src: photo19, width: 1920, height: 1080 },
  { id: 20, title: 'Round Coaster Set - with flowers and gold foil', src: photo20, width: 1920, height: 1080 },
  { id: 21, title: 'Round Coaster Set - with flowers and gold foil', src: photo21, width: 1920, height: 1080 },
  { id: 22, title: 'Round Coaster - with flowers and gold foil', src: photo22, width: 1920, height: 1080 },
  { id: 23, title: 'Round Coaster - with flowers and gold foil', src: photo23, width: 1920, height: 1080 },
  { id: 24, title: 'Round Coaster - with flowers and gold foil', src: photo24, width: 1920, height: 1080 },
  { id: 48, title: 'Geode Coasters - Purple and pink with silver foil', src: photo48, width: 1920, height: 1080 },
  { id: 50, title: 'Geode Coasters - green with gold foil', src: photo50, width: 1920, height: 1080 },
  { id: 51, title: 'Geode Coasters - green with gold foil', src: photo51, width: 1920, height: 1080 },
  { id: 52, title: 'Geode Coasters - green with gold foil', src: photo52, width: 1920, height: 1080 },
  { id: 53, title: 'Geode Coasters - green with gold foil', src: photo53, width: 1920, height: 1080 },
  { id: 54, title: 'Geode Coasters - with orange slice and gold foil', src: photo54, width: 1920, height: 1080 },
  { id: 55, title: 'Geode Coasters - with orange slice and gold foil', src: photo55, width: 1920, height: 1080 },
  { id: 56, title: 'Geode Coasters - with orange slice and gold foil', src: photo56, width: 1920, height: 1080 },
  { id: 57, title: 'Geode Coasters - with orange slice and gold foil', src: photo57, width: 1920, height: 1080 },
  { id: 60, title: 'Custom Geode Coasters - purple and white with gold foil', src: photo60, width: 1920, height: 1080 },
  { id: 61, title: 'Custom Geode Coasters - purple and white with gold foil', src: photo61, width: 1920, height: 1080 },
  { id: 62, title: 'Custom Geode Coasters - purple and white with gold foil', src: photo62, width: 1920, height: 1080 },
  { id: 64, title: 'Geode Coasters - Purple and pink with silver foil', src: photo64, width: 1920, height: 1080 }

]

const miscArray = [

  { id: 1, title: 'Bottle Top - with flowers and silver foil', src: photo1, width: 1920, height: 1080 },
  { id: 2, title: 'Bottle Top - with flowers and silver foil', src: photo2, width: 1920, height: 1080 },
  { id: 3, title: 'Bottle Top - with flowers and silver foil', src: photo3, width: 1920, height: 1080 },
  { id: 4, title: 'Bottle Top - with flowers and silver foil', src: photo4, width: 1920, height: 1080 },
  { id: 41, title: 'Medium Cube - with flowers', src: photo41, width: 1920, height: 1080 },
  { id: 44, title: 'Monstera Leaf - rose petals with white underneath', src: photo44, width: 1920, height: 1080 },
  { id: 46, title: 'Monstera Leaf - Green and white with gold glitter', src: photo46, width: 1920, height: 1080 },
  { id: 47, title: 'Monstera Leaf - Green and white with gold glitter', src: photo47, width: 1920, height: 1080 },
  { id: 49, title: 'Monstera Leaf - Purple and pink with silver foil', src: photo49, width: 1920, height: 1080 },
  { id: 58, title: 'Monstera Leaf - Green and dark gray with silver foil', src: photo58, width: 1920, height: 1080 },
  { id: 59, title: 'Monstera Leaf - Green and dark gray with silver foil', src: photo59, width: 1920, height: 1080 },
  { id: 63, title: 'Monstera Leaf - White', src: photo63, width: 1920, height: 1080 },

]

const photosArray = [

  { id: 1, title: 'Bottle Top - with flowers and silver foil', src: photo1, width: 1920, height: 1080 },
  { id: 2, title: 'Bottle Top - with flowers and silver foil', src: photo2, width: 1920, height: 1080 },
  { id: 3, title: 'Bottle Top - with flowers and silver foil', src: photo3, width: 1920, height: 1080 },
  { id: 4, title: 'Bottle Top - with flowers and silver foil', src: photo4, width: 1920, height: 1080 },
  { id: 5, title: 'Coaster holder - with flowers and gold foil', src: photo5, width: 1920, height: 1080 },
  { id: 6, title: 'Coaster holder - with flowers and gold foil', src: photo6, width: 1920, height: 1080 },
  { id: 7, title: 'Jewelry Medium Tray - with flowers and silver foil', src: photo7, width: 1920, height: 1080 },
  { id: 8, title: 'Jewelry Medium Tray - with flowers and silver foil', src: photo8, width: 1920, height: 1080 },
  { id: 9, title: 'Jewelry Medium Tray - with flowers and silver foil', src: photo9, width: 1920, height: 1080 },
  { id: 10, title: 'Coaster holder - with flowers and gold foil', src: photo10, width: 1920, height: 1080 },
  { id: 11, title: 'Coaster holder - with flowers and gold foil', src: photo11, width: 1920, height: 1080 },
  { id: 12, title: 'Coaster holder - with flowers and gold foil', src: photo12, width: 1920, height: 1080 },
  { id: 13, title: 'Coaster holder - with flowers and gold foil', src: photo13, width: 1920, height: 1080 },
  { id: 14, title: 'Round Coaster Set - with flowers and gold foil', src: photo14, width: 1920, height: 1080 },
  { id: 15, title: 'Round Coaster - with flowers and gold foil', src: photo15, width: 1920, height: 1080 },
  { id: 16, title: 'Round Coaster - with flowers and gold foil', src: photo16, width: 1920, height: 1080 },
  { id: 17, title: 'Round Coaster - with flowers and gold foil', src: photo17, width: 1920, height: 1080 },
  { id: 18, title: 'Round Coaster - with flowers and gold foil', src: photo18, width: 1920, height: 1080 },
  { id: 19, title: 'Round Coaster Set - with flowers and gold foil', src: photo19, width: 1920, height: 1080 },
  { id: 20, title: 'Round Coaster Set - with flowers and gold foil', src: photo20, width: 1920, height: 1080 },
  { id: 21, title: 'Round Coaster Set - with flowers and gold foil', src: photo21, width: 1920, height: 1080 },
  { id: 22, title: 'Round Coaster - with flowers and gold foil', src: photo22, width: 1920, height: 1080 },
  { id: 23, title: 'Round Coaster - with flowers and gold foil', src: photo23, width: 1920, height: 1080 },
  { id: 24, title: 'Round Coaster - with flowers and gold foil', src: photo24, width: 1920, height: 1080 },
  { id: 25, title: 'Jewelry Medium Tray - with flowers and gold foil', src: photo25, width: 1920, height: 1080 },
  { id: 26, title: 'Jewelry Medium Tray - with flowers and gold foil', src: photo26, width: 1920, height: 1080 },
  { id: 27, title: 'Jewelry Medium Tray - with flowers and gold foil', src: photo27, width: 1920, height: 1080 },
  { id: 28, title: 'XL Circle Tray - with flowers and gold foil with gold hardware', src: photo28, width: 1920, height: 1080 },
  { id: 29, title: 'XL Circle Tray - with flowers and gold foil with gold hardware', src: photo29, width: 1920, height: 1080 },
  { id: 30, title: 'Large Rectangle Tray - with flowers and gold foil with gold hardware', src: photo30, width: 1920, height: 1080 },
  { id: 31, title: 'Large Rectangle Tray - with flowers and gold foil with gold hardware', src: photo31, width: 1920, height: 1080 },
  { id: 32, title: 'Large Rectangle Tray - with flowers and gold foil with gold hardware', src: photo32, width: 1920, height: 1080 },
  { id: 33, title: 'Custom Wedding Bundle - with Silver Clear Tray', src: photo33, width: 1920, height: 1080 },
  { id: 34, title: 'Custom Wedding Bundle - with Silver Clear Tray', src: photo34, width: 1920, height: 1080 },
  { id: 35, title: 'Custom Wedding Bundle - with Silver Clear Tray', src: photo35, width: 1920, height: 1080 },
  { id: 36, title: 'Three Tier Tray - with flowers and small gold foil', src: photo36, width: 1920, height: 1080 },
  { id: 37, title: 'Three Tier Tray - with flowers and small gold foil', src: photo37, width: 1920, height: 1080 },
  { id: 38, title: 'Three Tier Tray - with flowers and small gold foil', src: photo38, width: 1920, height: 1080 },
  { id: 39, title: 'Custom Wedding Bundle - with Wood Tray', src: photo39, width: 1920, height: 1080 },
  { id: 40, title: 'Large Rectangle Tray - rose petals and white', src: photo40, width: 1920, height: 1080 },
  { id: 41, title: 'Medium Cube - with flowers', src: photo41, width: 1920, height: 1080 },
  { id: 42, title: 'Large Rectangle Tray - blue, white, and gold foil', src: photo42, width: 1920, height: 1080 },
  { id: 43, title: 'XL Circle Tray - rose petals and gold foil with white underneath', src: photo43, width: 1920, height: 1080 },
  { id: 44, title: 'Monstera Leaf - rose petals with white underneath', src: photo44, width: 1920, height: 1080 },
  { id: 45, title: 'Large Rectangle Tray - white, gold foil, and greenery with gold hardware', src: photo45, width: 1920, height: 1080 },
  { id: 46, title: 'Monstera Leaf - Green and white with gold glitter', src: photo46, width: 1920, height: 1080 },
  { id: 47, title: 'Monstera Leaf - Green and white with gold glitter', src: photo47, width: 1920, height: 1080 },
  { id: 48, title: 'Geode Coasters - Purple and pink with silver foil', src: photo48, width: 1920, height: 1080 },
  { id: 49, title: 'Monstera Leaf - Purple and pink with silver foil', src: photo49, width: 1920, height: 1080 },
  { id: 50, title: 'Geode Coasters - green with gold foil', src: photo50, width: 1920, height: 1080 },
  { id: 51, title: 'Geode Coasters - green with gold foil', src: photo51, width: 1920, height: 1080 },
  { id: 52, title: 'Geode Coasters - green with gold foil', src: photo52, width: 1920, height: 1080 },
  { id: 53, title: 'Geode Coasters - green with gold foil', src: photo53, width: 1920, height: 1080 },
  { id: 54, title: 'Geode Coasters - with orange slice and gold foil', src: photo54, width: 1920, height: 1080 },
  { id: 55, title: 'Geode Coasters - with orange slice and gold foil', src: photo55, width: 1920, height: 1080 },
  { id: 56, title: 'Geode Coasters - with orange slice and gold foil', src: photo56, width: 1920, height: 1080 },
  { id: 57, title: 'Geode Coasters - with orange slice and gold foil', src: photo57, width: 1920, height: 1080 },
  { id: 58, title: 'Monstera Leaf - Green and dark gray with silver foil', src: photo58, width: 1920, height: 1080 },
  { id: 59, title: 'Monstera Leaf - Green and dark gray with silver foil', src: photo59, width: 1920, height: 1080 },
  { id: 60, title: 'Custom Geode Coasters - purple and white with gold foil', src: photo60, width: 1920, height: 1080 },
  { id: 61, title: 'Custom Geode Coasters - purple and white with gold foil', src: photo61, width: 1920, height: 1080 },
  { id: 62, title: 'Custom Geode Coasters - purple and white with gold foil', src: photo62, width: 1920, height: 1080 },
  { id: 63, title: 'Monstera Leaf - White', src: photo63, width: 1920, height: 1080 },
  { id: 64, title: 'Geode Coasters - Purple and pink with silver foil', src: photo64, width: 1920, height: 1080 },
  { id: 65, title: '65', src: photo65, width: 1920, height: 1080 },
  { id: 66, title: '66', src: photo66, width: 1920, height: 1080 },
  { id: 67, title: '67', src: photo67, width: 1920, height: 1080 },
  { id: 68, title: '68', src: photo68, width: 1920, height: 1080 },
  { id: 69, title: '69', src: photo69, width: 1920, height: 1080 },
  { id: 70, title: '70', src: photo70, width: 1920, height: 1080 },
  { id: 71, title: '71', src: photo71, width: 1920, height: 1080 },
  { id: 72, title: '72', src: photo72, width: 1920, height: 1080 },
  { id: 73, title: '73', src: photo73, width: 1920, height: 1080 },
  { id: 74, title: '74', src: photo74, width: 1920, height: 1080 },
  { id: 75, title: '75', src: photo75, width: 1920, height: 1080 },
  { id: 76, title: '76', src: photo76, width: 1920, height: 1080 },
  { id: 77, title: '77', src: photo77, width: 1920, height: 1080 },
  { id: 78, title: '78', src: photo78, width: 1920, height: 1080 },
  { id: 79, title: '79', src: photo79, width: 1920, height: 1080 },
  { id: 80, title: '80', src: photo80, width: 1920, height: 1080 },
  { id: 81, title: '81', src: photo81, width: 1920, height: 1080 },
  { id: 82, title: '82', src: photo82, width: 1920, height: 1080 },
  { id: 83, title: '83', src: photo83, width: 1920, height: 1080 },
  { id: 84, title: '84', src: photo84, width: 1920, height: 1080 },
  { id: 85, title: '85', src: photo85, width: 1920, height: 1080 },
  { id: 86, title: '86', src: photo86, width: 1920, height: 1080 },
  { id: 87, title: '87', src: photo87, width: 1920, height: 1080 },
  { id: 88, title: '88', src: photo88, width: 1920, height: 1080 },
  { id: 89, title: '89', src: photo89, width: 1920, height: 1080 },
  { id: 90, title: '90', src: photo90, width: 1920, height: 1080 },
  { id: 91, title: '91', src: photo91, width: 1920, height: 1080 },
  { id: 92, title: '92', src: photo92, width: 1920, height: 1080 },
  { id: 93, title: '93', src: photo93, width: 1920, height: 1080 },
  { id: 94, title: '94', src: photo94, width: 1920, height: 1080 },
  { id: 95, title: '95', src: photo95, width: 1920, height: 1080 },
  { id: 96, title: '96', src: photo96, width: 1920, height: 1080 },
  { id: 97, title: '97', src: photo97, width: 1920, height: 1080 },
  { id: 98, title: '98', src: photo98, width: 1920, height: 1080 },
  { id: 99, title: '99', src: photo99, width: 1920, height: 1080 },
  { id: 100, title: '100', src: photo100, width: 1920, height: 1080 },
  { id: 101, title: '101', src: photo101, width: 1920, height: 1080 },
  { id: 102, title: '102', src: photo102, width: 1920, height: 1080 },
  { id: 103, title: '103', src: photo103, width: 1920, height: 1080 },
  { id: 104, title: '104', src: photo104, width: 1920, height: 1080 },
  { id: 105, title: '105', src: photo105, width: 1920, height: 1080 },
  { id: 106, title: '106', src: photo106, width: 1920, height: 1080 },
  { id: 107, title: '107', src: photo107, width: 1920, height: 1080 },
  { id: 108, title: '108', src: photo108, width: 1920, height: 1080 },

]

const fullGalleryArray = [
  'yes',
  'no'
]


export const images: string[] = miscArray.map(item => item.src);

const imageByIndex = (index: number): string => images[index % images.length];

export { photosArray, miscArray, trayArray, coasterArray, popularArray, fullGalleryArray, imageByIndex }
